










import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import FleetFilterService, { FleetFilterServiceS } from '@/modules/cars/modules/fleet/fleet-filter.service';
import CarsService, { CarsServiceS } from '../../cars.service';

@Component({
    components: { CustomSelect },
})
export default class PaymentTermsFilter extends Vue {
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @Inject(CarsServiceS) private carsService!: CarsService;
    @Inject(FleetFilterServiceS) private fleetFiltersService!: FleetFilterService;

    get items(): Item[] {
        const { paymentTerms } = this.carsFiltersService;

        return paymentTerms.map(({ value, disabled }) => ({
            name: String(value),
            value,
            disabled,
        }));
    }

    get currentValue() {
        return this.carsService.storeState.settings.paymentTerms;
    }
    set currentValue(value) {
        if (value) {
            this.fleetFiltersService.savePaymentTerms(value);
        }
    }
}
