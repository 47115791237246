










import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
// @ts-ignore
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import { $enum } from 'ts-enum-util';
import CAR_PRICE_TYPE from '@/modules/cars/constants/car-price-type.constant';
import CarsService, { CarsServiceS } from '../../cars.service';

@Component({
    components: { CustomSelect },
})
export default class PriceTypeFilter extends Vue {
    @Inject(CarsServiceS) private carsService!: CarsService;

    get items(): Item[] {
        return $enum(CAR_PRICE_TYPE).map((value): Item => ({
            value,
            name: this.$t(`cars.priceType.${value}`) as string,
        }));
    }

    get currentValue() {
        return this.carsService.storeState.settings.priceType;
    }
    set currentValue(value) {
        if (value) {
            this.carsService.storeState.settings.priceType = value;
        }
    }
}
