<template>
    <Tooltip :haveArrow="false" isEmpty>
        <template v-slot:popover-reference>
            <span class="icon-Info-icon"></span>
        </template>
        <template v-slot:popover-content>
            <CarFleetAvailabilityTooltip class="fleet-availability-tooltip"/>
        </template>
    </Tooltip>
</template>

<script>
import { Component, Vue } from 'vue-property-decorator';
import CarFleetAvailabilityTooltip from '@/modules/cars/components/car-fleet-availability-tooltip.vue';
import Tooltip from '@/modules/common/components/ui-kit/tooltip.vue';

@Component({
    components: {
        CarFleetAvailabilityTooltip,
        Tooltip,
    },
})
export default class CarTableAvailabilityTooltip extends Vue {
}
</script>

<style lang="scss" scoped>
    .icon-Info-icon {
        font-size: 12px;
        margin-left: 1px;
        cursor: pointer;
    }

    .fleet-availability-tooltip {
        &.fleet-availability-info-tooltip {
            top: -34px;
            left: 2px;
        }
    }

</style>
