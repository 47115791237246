










import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import { Popover } from 'element-ui';
import CAR_FUEL_TYPE from '@/modules/cars/constants/car-fuel-type.constant';

@Component({
    components: {
        CustomMultiSelect,
        'el-popover': Popover,
    },
})
export default class CarFuelTypeFilter extends Vue {
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @Inject(CarsServiceS) private carsService!: CarsService;
    disabled = false;

    get items() {
        return CAR_FUEL_TYPE.map(val => ({
            value: val,
            name: val,

        }));
    }

    get currentValue() {
        if (!this.carsService.storeState.settings.carFuelTypes) {
            return null;
        }

        return this.carsService.storeState.settings.carFuelTypes.map(val => ({
            value: val,
            name: val,
        })) || [];
    }

    set currentValue(value) {
        if (value) {
            this.carsService.storeState.settings.carFuelTypePreserve = true;
            this.carsService.storeState.settings.carFuelTypes = value.map(item => item.value);
            this.carsService.reloadDocument();
        }
    }

    get isAvgPrice() {
        return this.carsService.storeState.settings.isAvgPrice;
    }
}
