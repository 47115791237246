










import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CustomSelect, { Item } from '@/modules/common/components/ui-kit/custom-select.vue';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import CarsService, { CarsServiceS } from '@/modules/cars/cars.service';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';

@Component({
    components: { CustomSelect },
})
export default class CountryFilter extends Vue {
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @Inject(CarsServiceS) private carsService!: CarsService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;

    get items(): Item[] {
        const { countriesFilter } = this.carsFiltersService;

        if (!countriesFilter) {
            return [];
        }

        return countriesFilter.filter(country => this.carsFiltersService.filterPickUpCitiesByCountry(country).length > 0)
            .map(value => ({
                name: String(value),
                value,
            }));
    }

    get currentValue() {
        return this.carsService.storeState.settings.country;
    }

    set currentValue(value) {
        if (value) {
            this.carsSharedService.saveCountry(value);
        }
    }
}
