









import { Component, Vue, Watch } from 'vue-property-decorator';
import { Inject } from 'inversify-props';
import CustomMultiSelect from '@/modules/common/components/ui-kit/custom-multi-select.vue';
import CarsFiltersService, { CarsFiltersServiceS } from '@/modules/cars/cars-filters.service';
import FleetFilterService, { FleetFilterServiceS } from '@/modules/cars/modules/fleet/fleet-filter.service';
import _ from 'lodash';
import CarsSharedService, { CarsSharedServiceS } from '@/modules/cars/cars-shared.service';
import CarsService, { CarsServiceS } from '../../cars.service';

export interface IOption {
    name: string;
    value: string;
    disabled: boolean;
}

@Component({
    components: { CustomMultiSelect },
})
export default class CompetitorsFilter extends Vue {
    @Inject(CarsFiltersServiceS) private carsFiltersService!: CarsFiltersService;
    @Inject(FleetFilterServiceS) private fleetFiltersService!: FleetFilterService;
    @Inject(CarsSharedServiceS) private carsSharedService!: CarsSharedService;
    @Inject(CarsServiceS) private carsService!: CarsService;

    private loadingNewFilters = false;

    @Watch('options')
    onOptionsChange(opts: IOption[]) {
        this.loadingNewFilters = true;
        this.currentValue = opts;
        this.loadingNewFilters = false;
    }

    get currentValue() {
        const unselectedCompetitors = this.carsSharedService.getUnselectedCompetitors();
        const { competitors } = this.carsService.storeState.settings;

        if (!competitors) {
            return [];
        }

        const compFilter = new Set(this.carsFiltersService.competitorsFilter);
        return competitors.filter(item => !unselectedCompetitors.includes(item)).map(item => ({
            name: item,
            value: item,
            disabled: !compFilter.has(item),
        })) || [];
    }

    set currentValue(selectedItemValues) {
        const selectedValues = selectedItemValues.map(item => item.value);
        if (!this.loadingNewFilters) {
            const previouslySelectedCompetitorItems = this.currentValue;
            const previouslySelectedValues = previouslySelectedCompetitorItems.map(item => item.value);
            const newlySelectedCompetitors = _.difference(selectedValues, previouslySelectedValues);
            const newlyUnselectedCompetitors = _.difference(previouslySelectedValues, selectedValues);
            this.carsSharedService.updateUnselectedCompetitorsStore(newlySelectedCompetitors, 'unselect');
            this.carsSharedService.updateUnselectedCompetitorsStore(newlyUnselectedCompetitors, 'select');
            this.carsService.saveCompetitors(selectedValues);
            this.carsService.storeState.settings.competitors = selectedValues;
        } else {
            const newlySelectedCompetitorItems = this.currentValue;
            const newlySelectedValues = newlySelectedCompetitorItems.map(item => item.value);
            this.carsService.saveCompetitors(newlySelectedValues);
            this.carsService.storeState.settings.competitors = newlySelectedValues;
        }
    }

    get options() {
        this.carsService.storeState.settings.currentDocumentCompetitors = this.carsFiltersService.competitorsFilter;
        const compFilter = new Set(this.carsService.storeState.settings.currentDocumentCompetitors);
        return this.carsFiltersService.allCompetitors
            .map(val => ({
                value: val,
                name: val,
                disabled: !compFilter.has(val),
            })).sort((a, b) => {
                if (a.value < b.value) {
                    return -1;
                }
                return 1;
            });
    }
}
